$( document ).ready(function() {
    
    //$('#nav-icon').click(function(){
    //    $(this).toggleClass('open');
    //    $('.main').toggleClass('open');
    //    $('.sidebar-bg').toggleClass('open');
    //});

    
    $('.to_top_center a').click(function(){
        var speed = 1000; 
        var href= $(this).attr("href"); 
        var target = $(href == "#" || href == "" ? 'html' : href); 
        var position = target.offset().top-140; 
        $("html, body").animate({scrollTop:position}, speed, "swing"); 
        return false;
    });
    // _____________________________________________________________________
    
    
    var e=document.body.classList,lastScrollY = 300;
    window.addEventListener("scroll",function(){
        this.scrollY<lastScrollY?e.remove("hideUp"):e.add("hideUp");
        lastScrollY = this.scrollY;
        this.scrollY<200?e.remove("bg"):e.add("bg");
     
    })
    
    $(".dropdown-trigger").dropdown();
    
    //document.addEventListener("DOMContentLoaded", function() {
    var elems = document.querySelectorAll(".sidenav");
    var options = {
        edge:'right',
        onOpenStart:function name(params) {
            $('.sidenav-btn').addClass("menu-close");
            $('#nav-icon').addClass("open");
            
        },
        onCloseStart:function name(params) {
            $('.sidenav-btn').removeClass("menu-close");
            $('#nav-icon').removeClass("open");
        }
    };
    var instances = M.Sidenav.init(elems, options);
  
    document
        .querySelector(".sidenav-btn")
        .addEventListener("click", function() {
        var elem = document.querySelector(".sidenav");
        var instance = M.Sidenav.getInstance(elem);
    
        if (instance.isOpen) {
            console.log("Is open: I need to close it");
            instance.close();
        } else {
            console.log("Is closed: I need to open it");
            instance.open();
        }
        });
      //});



    
    $('.collapsible').collapsible();
    
   

    //document.addEventListener('DOMContentLoaded', function() {
    //    var elems = document.querySelectorAll('.collapsible');
    //    var instances = M.Collapsible.init(elems, options);
    //});
    // Or with jQuery
    //$('.collapsible').collapsible();
    

    
});



